<template>
    <transition name="fade">
        <div >
            <div class="mask" v-show="show"></div>
            <div class="Tips" v-show="show">
            
                <img v-show="messageType == 'success'" class="warningTip" src="../assets/image/successTip.png" alt="">
                <img v-show="messageType == 'warning'" class="succcessTip" src="../assets/image/warningTip.png" alt="">
                <div class="tipText">{{message}}</div>
                <div v-for="(item,index) in button" :key="index" class="buttonGroup">
                    <div v-if="!item.type" class="goButton" @click="go">{{item.text}}</div>
                    <div v-else class="knowButton" @click="hide">{{item.text}}</div>
                </div>
            </div>
        </div>
        
    </transition>
</template>
<script>
export default {
    prop:[],
    data() {
        return {
            // 消息提示内容
            message:'',
            // 控制弹窗显示与隐藏
            show:false,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button:[],
            // 回调函数
            callBack:()=>{},
            // 延时关闭时间
            displayTime:0

        };
    },
    created() 
    {
        if(this.displayTime && (this.button.length==0))
        {
            setTimeout(()=>{
                this.show = false
            },this.displayTime)
        }
    },
    methods: {
        /**
         * @name: 关闭弹窗
         * @author: LXY
         * @date: 20220707
         */                                       
        hide(){
            this.show = false
        },
        /**
         * @name: 点击现在前往后调用回调函数
         * @author: LXY
         * @date: 20220707
         */  
        go(){
            this.callBack()
            this.show = false
        }
    }
}
</script>

<style scoped>
.mask{
    z-index: 9999998 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: .5;
}
.Tips{
    position: fixed;
    z-index: 9999999 !important;
    top: 35vh;
    right: 35vw;
    width: 30vw;
    text-align: center;
    background-color: #fff;
    padding: 1.25vw 1.25vw 1.1458vw 1.25vw;
    border-radius: 0.625vw;
    box-shadow: 0.0521vw 0.0521vw 0.1563vw #00000029;
}
.warningTip,.succcessTip{
    width: 4.6875vw;
    height: 4.6875vw;
}
.knowButton{
    cursor: pointer;
    padding: 0 .7vw;
    box-sizing: border-box;
    display: inline-block;
    height: 2.0833vw;
    background-color: #0D5570;
    margin: 0 1vw;
    color:#FFFFFF;
    border: 0.0521vw solid #0D5570;
    border-radius: 0.2083vw;
    font:normal normal normal 0.8333vw  Source Han Sans CN;
    line-height: 2.0833vw;
}
.goButton{
    cursor: pointer;
    padding: 0 .7vw;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 1vw;
    height: 2.0833vw;
    background-color: #FFFFFF;
    color: #000000;
    border: 0.0521vw solid #999999;
    border-radius: 0.2083vw;
    font:normal normal normal 0.8333vw  Source Han Sans CN;
    line-height: 2.0833vw;
}
.tipText{
    font:normal normal normal 1vw  Source Han Sans CN;
    color: #0D5570;
    margin: 1vw 0 1.3vw 0;
}
.buttonGroup{
    display: inline-block;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>