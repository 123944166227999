<template>
    <div id="app">
        <router-view  v-if="isrouterAlive"/>
    </div>
</template>

<script>
export default {
    name: "app",
    provide(){
        return{
            reload:this.reload
        }
    },
    data(){
        return{
            isrouterAlive:true
        }
        
    },
    methods:{
        reload(){
            this.isrouterAlive = false;
            this.$nextTick(() => {
                this.isrouterAlive = true;
            })
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/css/style.scss";

// 自定义loading样式
.create-isLoading {
    .el-loading-spinner {
        top: 50% !important;
        left: 50%  !important;
        margin-left: -55px  !important;
        background: rgba(0, 0, 0, 0.7) !important;
        padding: 20px  !important;
        border-radius: 4px  !important;
        width: auto  !important;
        text-align: center  !important;
        position: absolute  !important;
        z-index:10000;
        i {
            color: #eee  !important;
        }

        .el-loading-text {
            color: #eee  !important;
        }
    }
}

/* App
-------------------------- */
html,
body,
#app {
    height: 100%;
}

*,
*::before,
*::after {
    user-select: none;
    box-sizing: border-box;
    margin: 0;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    overflow: auto;
}

::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    min-height: 25px;
    min-width: 25px;
    border: 1px solid #e0e0e0;
}

::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border: 1px solid #efefef;
}
</style>
