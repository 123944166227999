import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

// 解决vue3.0跳转路由与当前路由相等的bug
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  // 首页
  /*{
    path: "/",
    name: "index",
    redirect: "/login",
    meta: { title: "实验智慧平台" },
    component: () => import("../views/Home.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },//*/
  {
    path: "/",
    redirect: "/teacher/calendar",
    name: "Manager",
    meta: { title: "实验智慧平台" },
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "teacher/index",
        name: "index",
        meta: { title: "" },
        // component: () => import("../views/teacher/index/Index.vue"),
        component: () => import("../views/teacher/calendar/Calendar.vue"),
      },//*/
      {
        path: "teacher/calendar",
        name: "teacher/calendar",
        meta: { title: "" },
        component: () => import("../views/teacher/calendar/Calendar.vue"),
      },
      {
        path: "teacher/studentslist",
        name: "teacher/studentslist",
        meta: { title: "" },
        component: () => import("../views/teacher/calendar/StudentsList.vue"),
      },
      {
        path: "teacher/ExamsList",
        name: "teacher/ExamsList",
        meta: { title: "" },
        component: () => import("../views/teacher/calendar/ExamsList.vue"),
      },
      // ===========================================================================
      // 素材路由组
      {
        path: "teacher/articleList",
        name: "articleList",
        meta: { title: "" },
        component: () => import("../views/teacher/article/articleList.vue"),
      },
      {
        path: "teacher/article/articleCategory",
        name: "articleCategory",
        meta: { title: "" },
        component: () => import("../views/teacher/article/articleCategory.vue"),
      },
      {
        path: "teacher/article/articleUpdate",
        name: "articleUpdate",
        meta: { title: "" },
        component: () => import("../views/teacher/article/articleUpdate.vue"),
      },
      {
        path: "teacher/article/articlePreview",
        name: "articlePreview",
        meta: { title: "" },
        component: () => import("../views/teacher/article/articlePreview.vue"),
      },
      // ============================================================================
      // 其他 路由组
      // ADD BY LANXINYU ============================================================
      {
        path: "teacher/other/colleague",
        name: "colleague",
        meta: { title: "" },
        component: () => import("../views/teacher/other/colleague.vue"),
      },
      {
        path: "teacher/other/navigation",
        name: "navigation",
        meta: { title: "" },
        component: () => import("../views/teacher/other/navigation.vue"),
      },
      {
        path: "teacher/other/workStatPage",
        name: "workStatPage",
        meta: { title: "" },
        component: () => import("../views/teacher/other/workStatPage.vue"),
      },
      {
        path: "teacher/other/searchScore",
        name: "searchScore",
        meta: { title: "" },
        component: () => import("../views/teacher/other/searchScore.vue"),
      },
      {
        path: "teacher/other/fenxi",
        name: "fenxi",
        meta: { title: "" },
        component: () => import("../views/teacher/other/fenxi.vue"),
      },
      {
        path: "teacher/other/xueqing",
        name: "xueqing",
        meta: { title: "" },
        component: () => import("../views/teacher/other/xueqing.vue"),
      },
      {
        path: "teacher/other/tongji",
        name: "tongji",
        meta: { title: "" },
        component: () => import("../views/teacher/other/tongji.vue"),
      },
      {
        path: "teacher/other/HCTJ",
        name: "HCTJ",
        meta: { title: "" },
        component: () => import("../views/teacher/other/HCTJ.vue"),
      },
      // ============================================================================
      
      // ============================================================================
      // 题库路由组
      {
        path: "teacher/questionBank",
        name: "questionBank",
        meta: { title: "" },
        component: () => import("../views/teacher/questionbank/questionBank.vue"),
      },
      {
        path: "teacher/questionBankUpdate",
        name: "questionBankUpdate",
        meta: { title: "" },
        component: () => import("../views/teacher/questionbank/questionBankUpdate.vue"),
      },
      {
        path: "teacher/questionList",
        name: "questionList",
        meta: { title: "" },
        component: () => import("../views/teacher/questionbank/questionList.vue"),
      },
      {
        path: "teacher/questionPreview",
        name: "questionPreview",
        meta: { title: "" },
        component: () => import("../views/teacher/questionbank/questionPreview.vue"),
      },
      // ============================================================================
      // 报告路由组
      {
        path: "teacher/report/reportCorrection",
        name: "ReportCorrection",
        meta: { title: "报告评阅" },
        component: () => import("@/views/teacher/report/Correction"),
      },
      {
        path: "teacher/report/reportTemplate",
        name: "reportTemplate",
        meta: { title: "" },
        component: () => import("@/views/teacher/report/reportTemplate.vue"),
      },
      {
        path: "teacher/reportTemplateUpdate",
        name: "reportTemplateUpdate",
        meta: { title: "编辑报告模板" },
        component: () =>
          import("@/views/teacher/report/reportTemplateUpdate.vue"),
      },
      {
        path: "teacher/report/preview",
        name: "preview",
        meta: { title: "" },
        component: () => import("@/views/teacher/report/Preview.vue"),
      },
      {
        path: "teacher/reportTemplateFile",
        name: "reportTemplateFile",
        meta: { title: "报告模板附件" },
        component: () => import("../views/teacher/report/reportTemplateFile.vue"),
      },
      // ============================================================================
      // 项目路由组
      {
        path: "teacher/projectList",
        name: "projectList",
        meta: { title: "" },
        component: () => import("../views/teacher/project/projectList.vue"),
      },
      {
        path: "teacher/projectList/:projectID",
        name: "projectList",
        meta: { title: "" },
        component: () => import("../views/teacher/project/projectList.vue"),
      },
      // ============================================================================
      // 群组路由组
      {
        path: "teacher/groupList",
        name: "groupList",
        meta: { title: "" },
        component: () => import("../views/teacher/group/groupList.vue"),
      },
      // 显示发布成绩路由
      {
        path: "teacher/unpublishGrade",
        name: "unpublishGrade",
        meta: { title: "" },
        component: () => import("../views/teacher/group/unpublishGrade.vue"),
      },
      // ============================================================================
      // 课程路由组
      {
        path: "teacher/course",
        name: "course",
        meta: { title: "" },
        component: () => import("../views/teacher/course/course.vue"),
      },
      {
        path: "teacher/course/:courseId",
        name: "course",
        meta: { title: "" },
        component: () => import("../views/teacher/course/course.vue"),
      },
      // ============================================================================
      // 中心路由组
      {
        path: "teacher/center/webPageContent",
        name: "webPageContent",
        meta: { title: "网站内容列表" },
        component: () => import("../views/teacher/center/webPageContent.vue"),
      },
      {
        path: "teacher/center/webPageUpdate",
        name: "webPageUpdate",
        meta: { title: "编辑网站内容" },
        component: () => import("../views/teacher/center/webPageUpdate.vue"),
      },
      {
        path: "teacher/center/webNewsList",
        name: "webNewsList",
        meta: { title: "网站新闻列表" },
        component: () => import("../views/teacher/center/webNewsList.vue"),
      },
      {
        path: "teacher/center/webNewsUpdate",
        name: "webNewsUpdate",
        meta: { title: "编辑网站新闻" },
        component: () => import("../views/teacher/center/webNewsUpdate.vue"),
      },
      {
        path: "teacher/center/webNoticeList",
        name: "webNoticeList",
        meta: { title: "网站公告列表" },
        component: () => import("../views/teacher/center/webNoticeList.vue"),
      },
      {
        path: "teacher/center/webNoticeUpdate",
        name: "webNoticeUpdate",
        meta: { title: "编辑网站公告" },
        component: () => import("../views/teacher/center/webNoticeUpdate.vue"),
      },
      {
        path: "teacher/center/memberList",
        name: "memberList",
        meta: { title: "中心成员管理" },
        component: () => import("../views/teacher/center/memberList.vue"),
      },
      {
        path: "teacher/center/courseList",
        name: "courseList",
        meta: { title: "中心课程管理" },
        component: () => import("../views/teacher/center/courseList.vue"),
      },
      {
        path: "teacher/center/openList",
        name: "openList",
        meta: { title: "开课单元管理" },
        component: () => import("../views/teacher/center/openList.vue"),
      },
      {
        path: "teacher/center/studentsList",
        name: "openList",
        meta: { title: "学生列表管理" },
        component: () => import("../views/teacher/center/StudentsList.vue"),
      },
      {
        path: "teacher/center/tongjis",
        name: "tongjis",
        meta: { title: "中心数据统计" },
        component: () => import("../views/teacher/center/tongjis.vue"),
      },
      // 帮助
      /*{
        path: "teacher/help",
        name: "help",
        meta: { title: "" },
        component: () =>
          import("../views/manager/teacher/map/help.vue"),
      },//*/
      // ============================================================================
      // 学生路由开始
      // 学生报告
      {
        path: "student/reportList",
        name: "reportList",
        meta: { title: "" },
        component: () => import("../views/student/report/reportList.vue"),
      },
      {
        path: "student/reportDetail",
        name: "reportDetail",
        meta: { title: "" },
        component: () => import("../views/student/report/reportDetail.vue"),
      },

      {
        path: "DY/index",
        name: "index",
        meta: { title: "" },
        component: () => import("../views/DY/index.vue"),
      },
      {
        path: "DY/zonglan",
        name: "zonglan",
        meta: { title: "" },
        component: () => import("../views/DY/zonglan.vue"),
      },
      {
        path: "DY/fenxi",
        name: "fenxi",
        meta: { title: "" },
        component: () => import("../views/DY/fenxi.vue"),
      },
      {
        path: "DY/xueqing",
        name: "xueqing",
        meta: { title: "" },
        component: () => import("../views/DY/xueqing.vue"),
      },
      {
        path: "DY/tongji",
        name: "tongji",
        meta: { title: "" },
        component: () => import("../views/DY/tongji.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/manager/userList",
    name: "Admin",
    meta: { title: "管理端" },
    component: () => import("../views/Home.vue"),
    children: [
      //项目路由组
      {
        path: "manager/projectList",
        name: "manager/projectList",
        meta: { title: "" },
        component: () => import("../views/manager/project/projectList.vue"),
      },
      // ============================================================================

      //日志路由组
      {
        path: "manager/logList",
        name: "manager/logList",
        meta: { title: "" },
        component: () => import("../views/manager/log/logList.vue"),
      },
      {
        path: "manager/schoolManager",
        name: "schoolManager",
        meta: { title: "" },
        component: () => import("../views/manager/school/schoolManager.vue"),
      },
      {
        path: "manager/user",
        name: "userManager",
        meta: { title: "" },
        component: () => import("../views/manager/user/userList.vue"),
      },
      {
        path: "manager/userRepeat",
        name: "userRepeat",
        meta: { title: "" },
        component: () => import("../views/manager/user/userRepeat.vue"),
      },
      {
        path: "manager/userInfo",
        name: "userInfo",
        meta: { title: "" },
        component: () => import("../views/manager/user/userInfo.vue"),
      },
      {
        path: "manager/user/grantProject",
        name: "grantProject",
        meta: { title: "" },
        component: () => import("../views/manager/user/grantProject.vue"),
      },
      {
        path: "manager/user/lockProject",
        name: "lockProject",
        meta: { title: "" },
        component: () => import("../views/manager/user/lockProject.vue"),
      },
      {
        path: "manager/feedBack",
        name: "feedBack",
        meta: { title: "" },
        component: () =>
          import("../views/manager/feedBack/feedBack.vue"),
      },
      {
        path: "manager/keyWords",
        name: "keyWords",
        meta: { title: "" },
        component: () =>
          import("../views/manager/keyWords/keyWords.vue"),
      },
      {
        path: "manager/trialApplication",
        name: "trialApplication",
        meta: { title: "" },
        component: () =>
          import("../views/manager/trialApplication/trialApplication.vue"),
      },

      {
        path: "manager/feedBack/replay",
        name: "replay",
        meta: { title: "" },
        component: () =>
          import("../views/manager/feedBack/replay.vue"),
      },
      {
        path: "manager/cert",
        name: "cert",
        meta: { title: "" },
        component: () =>
          import("../views/manager/cert/cert.vue"),
      },
      {
        path: "manager/openCourse",
        name: "openCourse",
        meta: { title: "" },
        component: () =>
          import("../views/manager/openCourse/openCourse.vue"),
      },
      {
        path: "manager/editorWeb",
        name: "editorWeb",
        meta: { title: "" },
        component: () => import("../views/manager/editorWeb/editorWeb.vue"),
      },
      {
        path: "manager/editWebDetail",
        name: "editReportWeb",
        meta: { title: "" },
        component: () =>
          import("../views/manager/editorWeb/editWebDetail.vue"),
      },
      {
        path: "manager/expManager",
        name: "expManager",
        meta: { title: "" },
        component: () =>
          import("../views/manager/exp/expManager.vue"),
      },
      {
        path: "manager/managerExpStat",
        name: "managerStat",
        meta: { title: "" },
        component: () =>
          import("../views/manager/exp/managerStat.vue"),
      },
      {
        path: "manager/labManager",
        name: "labManager",
        meta: { title: "" },
        component: () =>
          import("../views/manager/exp/labManager.vue"),
      },
      {
        path: "manager/expDetail",
        name: "expDetail",
        meta: { title: "" },
        component: () =>
          import("../views/manager/exp/expDetail.vue"),
      },
      {
        path: "manager/exp/developerList",
        name: "developerList",
        meta: { title: "" },
        component: () =>
          import("../views/manager/exp/developerList.vue"),
      },
      {
        path: "teacher/developer/virtualExp",
        name: "virtualExp",
        meta: { title: "" },
        component: () =>
          import("../views/teacher/developer/virtualExp.vue"),
      },
      {
        path: "teacher/developer/feedBack",
        name: "virtualExp",
        meta: { title: "" },
        component: () =>
          import("../views/teacher/developer/feedBack.vue"),
      },
      {
        path: "teacher/developer/personalInfo",
        name: "personalInfo",
        meta: { title: "" },
        component: () =>
          import("../views/teacher/developer/personalInfo.vue"),
      },
      {
        path: "manager/statsList",
        name: "manager/statsList",
        meta: { title: "" },
        component: () => import("../views/manager/stats/statsList.vue"),
      },
      {
        path: "manager/loginTeacher",
        name: "loginTeacher",
        meta: { title: "" },
        component: () => import("../views/manager/other/loginTeacher.vue"),
      },
      {
        path: "manager/exportPDF",
        name: "exportPDF",
        meta: { title: "" },
        component: () => import("../views/manager/other/exportPDF.vue"),
      },
      
      {
        path: "manager/task",
        name: "task",
        meta: { title: "" },
        component: () => import("../views/manager/task/task.vue"),
      },
      {
        path: "manager/taskDetail",
        name: "taskDetail",
        meta: { title: "" },
        component: () => import("../views/manager/task/taskDetail.vue"),
      },
      {
        path: "manager/exportgameData",
        name: "exportgameData",
        meta: { title: "" },
        component: () => import("../views/manager/other/exportgameData.vue"),
      },
      {
        path: "manager/batchCreate",
        name: "batchCreate",
        meta: { title: "" },
        component: () => import("../views/manager/other/batchCreate.vue"),
      },
      {
        path: "manager/sendallTeacher",
        name: "sendallTeacher",
        meta: { title: "" },
        component: () => import("../views/manager/other/sendallTeacher.vue"),
      },
      {
        path: "manager/exportExamPage",
        name: "exportExamPage",
        meta: { title: "" },
        component: () => import("../views/manager/other/exportExamPage.vue"),
      },
      {
        path: "manager/center",
        name: "center",
        meta: { title: "" },
        component: () => import("../views/manager/center/index.vue"),
      },
      {
        path: "manager/apply",
        name: "apply",
        meta: { title: "" },
        component: () => import("../views/manager/apply/index.vue"),
      },
      // ============================================================================

      // {
      //   path: "manager/Statistics",
      //   name: "manager/Statistics",
      //   meta: { title: "" },
      //   component: () => import("../views/manager/statistics/Statistics.vue"),
      // },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "*",
    name: "Error",
    meta: { title: "找不到页面" },
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/",
    redirect: "/schoolAdmin/center",
    name: "schoolAdmin",
    meta: { title: "学校管理员" },
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "schoolAdmin/calendar",
        name: "schoolAdmin/calendar",
        meta: { title: "" },
        component: () => import("../views/schoolAdmin/calendar/calendar.vue"),
      },
      {
        path: "schoolAdmin/center",
        name: "schoolAdmin/center",
        meta: { title: "" },
        component: () => import("../views/schoolAdmin/center/index.vue"),
      },
      {
        path: "schoolAdmin/teacher",
        name: "schoolAdmin/teacher",
        meta: { title: "" },
        component: () => import("../views/schoolAdmin/teacher/teacher.vue"),
      },
      {
        path: "schoolAdmin/exp",
        name: "schoolAdmin/exp",
        meta: { title: "" },
        component: () => import("../views/schoolAdmin/exp/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // 每次跳路由之前先检查session，session存在正常跳路由，不存在session跳登录页
  if (to.meta.requireAuth) 
  {
    axios
      .get("/index.php/managerbase/getsessionrequest")
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        if (err.data && err.data.code == -200) 
        {
          next({
            path: "/login"
          });
        }
      });
  }
  // 标题设置
  document.title = to.meta.title ? to.meta.title : process.env.VUE_APP_TITLE;
  next();
});
export default router;
