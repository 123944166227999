import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // 控制引导用户去帮助页面
        help:false,
        //选择的试题数组
        questionSelection:[],
    },
    mutations: {
        // 改变控制帮助对话框变量
        isHelpShow (state, help) {
            state.help = help
            }
    },
    actions: {},
    modules: {}
});
