<template>
    <transition name="fade">
        <div v-if="show">
            <div class="mask"></div>
            <div class="loading" >
                <div class="iconBox">
                <div class="loadingBox1">
                    <div class="icon1"></div>
                    <div class="icon2"></div>
                </div>
                <div class="loadingBox2">
                    <div class="icon3"></div>
                    <div class="icon4"></div>
                </div>
                
            </div>
            <div class="tipText">加载中</div>
            </div>
            <!-- <div class="tipText">加载中</div> -->
        </div>
        
    </transition>
</template>
<script>
export default {
    prop:[],
    data() {
        return {
            // 消息提示内容
            message:'',
            // 控制弹窗显示与隐藏
            show:false,
            // 消息提示类型（success-成功,warning-警告/失败）
            button:[],
            // 回调函数
            callBack:()=>{},
            // 延时关闭时间
            displayTime:0

        };
    },
    created() {
    },
    methods: {
        /**
         * @name: 关闭弹窗
         * @author: LXY
         * @date: 20220707
         */                                       
        hide(){
            this.show = false
        },
        /**
         * @name: 点击现在前往后调用回调函数
         * @author: LXY
         * @date: 20220707
         */  
        go(){
            this.callBack()
            this.show = false
        }
    }
}
</script>

<style scoped>
.loadingBox1{
    position: absolute;
    top: 32%;
    left: 35%;
}
.loadingBox1{
        animation: clockwise 2s infinite linear;
        position: absolute;
        top: 32%;
        left: 35%;
         /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.loadingBox2{
        position: absolute;
        /* width: 100%;
        height: 100%; */
        position: absolute;
top: 28%;
    left: 27%;
         animation: clockinverse 2s infinite linear;
         /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
.mask{
    z-index: 9999998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    opacity: .5;
}
.iconBox{
    position: relative;
    width: 100%;
    height: 100%;
    /* animation: clockwise 2s infinite linear; */
}
.icon1{
    width: 1.3vw;
    height: 1.3vw;
    border: 0.3125vw solid #0d5570;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-left-radius: 1.3vw;
    transform: rotate(-45deg) translateZ(0);
    display: inline-block;
}
.icon2{
    width: 1.3vw;
    height: 1.3vw;
	border: 0.3125vw solid #0d5570;
	border-left-width: 0;
	border-top-width: 0;
    border-bottom-right-radius: 1.3vw;
    transform: rotate(-45deg) translateZ(0);
     display: inline-block;

}
.icon3{
    width: 2vw;
    height: 2.2vw;
    border: 0.3125vw solid #87B8C9;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-left-radius: 3.125vw;
    transform: rotate(-90deg) translate3d(-1vw,0.2vw,0);
    display: inline-block;
}
.icon4{
    width: 2vw;
    height: 2.2vw;
    border: 0.3125vw solid #87B8C9;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-right-radius: 3.125vw;
    transform: rotate(-89deg) translate3d(0.8vw,-0.1vw,0);
    display: inline-block;
}
.loading{
    position: fixed;
    z-index: 9999999;
    top: 35vh;
    left: 50vw;
    transform: translate(-50%);
    width: 8.5417vw;
    height:9.6875vw;
    text-align: center;
    background-color: rgb(255, 255, 255,.6);
    border-radius: 0.625vw;
    box-shadow: 0.0521vw 0.0521vw 0.1563vw #00000029;
    /* opacity: 0.6; */
}
.warningTip,.succcessTip{
    width: 4.6875vw;
    height: 4.6875vw;
}
.tipText{
    font:normal normal normal 1vw  Source Han Sans CN;
    color: #0D5570;
    margin: 1vw 0 1.3vw 0;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@keyframes clockwise {
    0%{
        transform: rotate(0deg) translateZ(0);
    }
    100%{
        transform: rotate(360deg) translateZ(0);
    }
}
@keyframes clockinverse {
    0%{
        transform: rotate(0deg) translateZ(0);
    }
    100%{
        transform: rotate(-360deg) translateZ(0);
    }
}
</style>