import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Sortable from 'sortablejs'
import Tips from './components/tips.vue'
import Loading from './components/loading/index'
import "./plugins/element.js";
import "./request";
// 引入axios
import axios from "axios";
// 挂载到vue原型链上(全局引入axios)
Vue.prototype.axios = axios; // this.axios
// 全局挂载拖拽组件
Vue.prototype.Sortable = Sortable;
const tipsBox = Vue.extend(Tips)
Tips.install = function (data) {
  let instance = new tipsBox({
    data
  }).$mount()
  document.body.appendChild(instance.$el)
  Vue.nextTick(() => {
    instance.show = true
    // show 和弹窗组件里的show对应，用于控制显隐
  })
}
Vue.prototype.$Tips = Tips.install

Vue.prototype.$Loading = Loading()
Vue.config.productionTip = false;

console.log(process.env.NODE_ENV)

new Vue({
    router,
    store,
    axios,
    render: h => h(App)
}).$mount("#app");
