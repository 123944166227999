import { Message, Loading } from "element-ui"; //项目已经全局引入element的话可以不单独引入
/**
 * @name: 更换一个对象中所有属性的属性名
 * @author: lxy
 * @param:	object 要更改属性名的对象
 * @param:	names 想要的属性名 
 *  调用示例：changeObjectkey(object, ["编号", "姓名", "年龄", "性别"])
 */
export function changeObjectkey(object, names) {
    // const newArr = [];
    // object.forEach((item) => {
        const objL = Object.entries(object);
        objL.forEach((ite, i) => {
        if (names[i] === undefined) return;
        ite[0] = names[i];
        });
        object = Object.fromEntries(objL);
    //   newArr.push(item);
    // });
    return object;
}
export function dateTimeFormat(date)
{
    const autoZero = (n) => (String(n).length === 1 ? '0' : '') + n
    const strToTimestamp = (str) => Date.parse(str.replace(/-/gi, '/'))
    let oriSecond = strToTimestamp(date) / 1000
    let curSecond = parseInt(new Date().getTime() / 1000)
    let diffSecond = curSecond - oriSecond
    let curDate = new Date(curSecond * 1000)
    let oriDate = new Date(oriSecond * 1000)
    let Y = oriDate.getFullYear()
    let m = oriDate.getMonth() + 1
    let d = oriDate.getDate()
    let H = oriDate.getHours()
    let i = oriDate.getMinutes()
    if (diffSecond < 60) 
    {
        return '刚刚'
    } 
    else if (diffSecond < 3600) 
    {
        return `${Math.floor(diffSecond / 60)}分钟前`
    } 
    else if (curDate.getFullYear() === Y && curDate.getMonth() + 1 === m && curDate.getDate() === d) 
    {
        return `今天${autoZero(H)}:${autoZero(i)}`
    }
    let mewDate = new Date((curSecond - 86400) * 1000)
    if (mewDate.getFullYear() === Y && mewDate.getMonth() + 1 === m && mewDate.getDate() === d) 
    {
        return `昨天${autoZero(H)}:${autoZero(i)}`
    } 
    else if (curDate.getFullYear() === Y) 
    {
        return `${autoZero(m)}月${autoZero(d)}日`
    }
    return `${Y}年${autoZero(m)}月${autoZero(d)}日`
}

export function getDurantion(time)
{
    // 把不是数字类型的参数转为数字类型
    let Time = time - 0
    let hour = parseInt(Time / 3600) > 9 ? parseInt(Time / 3600) : '0' + parseInt(Time / 3600);
    // 获取分钟数，不足两位补0
    // let minute = parseInt((Time / 60)%60) > 9 ? parseInt((Time / 60)%60) : '0' + parseInt((Time / 60)%60);
    let minute = parseInt((Time / 60)%60)
    // 获取秒数，不足两位补0
    // let second = parseInt(Time % 60) > 9 ? parseInt(Time % 60) : '0' + parseInt(Time % 60);
    let second = parseInt(Time % 60)
    // 格式化时间（HH:MM:SS）
    return  minute + '分' + second + '秒';
}

export function avgScore(num,sign=true)
{
    if(num <= 0)
    {
        return [];
    }
    var array = [];
    if(sign)
    {
        let score = 100 / num;
        let scoreAvg = score.toFixed(2);
        let odd = 100 - scoreAvg * num;
        for(var i = 0; i < num; i++)
        {
            let json = {};
            if(i == (num - 1) && odd != 0)
            {
                json.a = i + 1;
                json.b = (parseFloat(odd) + parseFloat(scoreAvg)).toFixed(2);
            }
            else
            {
                json.a = i + 1;
                json.b = parseFloat(scoreAvg).toFixed(2);
            }
            array.push(json);
        }
    }
    else
    {
        for(var i = 0; i < num; i++)
        {
            let json = {};
            json.a = i + 1;
            json.b = 0;
            array.push(json);
        }
    }
    return array;
}
/**
 * @name: 获取指定日期的年月,返回值例子：2022年4月
 * @author: LXY
 * @date: 2022-04-24 11:02:46
 */
export function dateFormat(fmt, date) 
{
    // 调用示例：dateFormat('YYYY年-m月',new Date()) -> 2022-04-08 14:44:42
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) 
    {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) 
        {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}
/**
 * @name: 获取当前时间的后一个月
 */
export function  getnextMonthdate()
{
    var date_now = new Date()//获取当前时间
    var year = date_now.getFullYear()//获取当前时间的年份
    var month = date_now.getMonth()//获取当前时间的月份
    var day = date_now.getDate()//获取当前时间的日
    var days = new Date(year,month,0)//将获取到的年月赋值给days
    days = days.getDate()//获取当前年月的日
    var year2 = year
    var month2 =  parseInt(month+2)//获取当前月份的1一个月以后的月份
    if(month2 > 12)
    { //考虑到12月要是获取一个月以后，就是一月，年份需要加一 ，一年没有13月，所以%12，取得来年1月
        year2 = parseInt(year2) + 1
        month2 = parseInt(month2) % 12
    }
    var day2 = day
    var days2 = new Date(year2,month2,0)
    days2 = days2.getDate()
    if(day2>days2)
    {//获取了当前年份的日和1个月以后的日，为的就是判断如果前一个月是有31号，后一个月没有，就将一个月以后的日期取到，赋值给day2
        day2 = days2
    }
    if(month2 < 10)
    {
        month2 = '0' + month2
    }
    var time = year2 + '-' + month2 + '-' + day2 +' '+date_now.getHours()+':'+date_now.getMinutes()+':'+date_now.getSeconds()
    return time
}
/**
 * @name: 设置cookie值
 */
export function setCookie(cname, cvalue, exdays = 720) 
{
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
/**
 * @name: 获取cookie值
 */
export function getCookie(cname) 
{
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) 
    {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) != -1) 
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
/**
 * @name: 清除cookie值
 */
export function clearCookie(cname) 
{
    var d = new Date();
    d.setTime(-1);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=''; " + expires;
}

/**
 * @name: 开启loading
 */
export function startLoading(msg = "加载中……") 
{
    let loading = Loading.service({
        lock: true,
        text: msg,
        background: "rgba(0,0,0,0)",
        customClass: 'create-isLoading',  // *这里设置他的class名称,这里最重要
    });
    return loading;
}

/**
 * @name: 关闭loading
 */
export function endLoading(loading) 
{
    if (loading) 
    {
        // loading显示时间，至少显示0.5秒  解决闪屏的问题
        sleep(500).then(() => {
            // 这里写sleep之后需要去做的事情
            loading.close();
        });
    }
}

/**
 * @name: 生成指定长度随机字符串
 * @param:	e	number	长度
 */
export function randomString(e) 
{
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
}
/**
 * @name: 程序停止执行时间（睡一会儿）
 * @param:	time	number	显示时间
 */
export function sleep(time) 
{
    return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * @name: 根据索引返回对应字母
 * @param:	index	number	索引
 */
export function returnLetterByIndex(index) {
    let array = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
    ];
    return array[index];
}

export function period(start) 
{
    let period = (Date.now() - start)/1000/60;
    return period
}


export function encryptCode(param) 
{
    var text = JSON.stringify(param);
    var key = CryptoJS.enc.Latin1.parse('elaz5AFjNXth2NOi'); //为了避免补位，直接用16位的秘钥
    var iv = CryptoJS.enc.Latin1.parse('xoIqqTdOQyvEGWLf'); //16位初始向量
    var encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    }).toString();
    return encrypted;
}

export function decryptCode(param) 
{
    var key = CryptoJS.enc.Latin1.parse('elaz5AFjNXth2NOi'); //为了避免补位，直接用16位的秘钥
    var iv = CryptoJS.enc.Latin1.parse('xoIqqTdOQyvEGWLf'); //16位初始向量
    const decrypt = CryptoJS.AES.decrypt(param, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    }).toString(CryptoJS.enc.Utf8);
    return decrypt;
}

/**
* 计算字符串中特定字符出现的次数。
* @param {string} str 传入的字符串
* @param {string} target 字符串中的某个字符
* @param {boolean} caseSensitive 是否区分大小写，默认为不区分
* @returns {number} 字符出现的次数
*/
export const countOccurrence = (str, target, caseSensitive = false) => {
    // 根据传入的 caseSensitive 参数选择是否进行大小写转换
    const modifiedStr = caseSensitive ? str : str.toLowerCase();
    const modifiedTarget = caseSensitive ? target : target.toLowerCase();

    // 创建一个空对象用于存储字符出现的次数
    const charCount = {};

    // 遍历字符串，统计字符出现的次数
    for (const char of modifiedStr) {
    charCount[char] = (charCount[char] || 0) + 1;
    }

    // 返回特定字符出现的次数，如果不存在则返回 0
    return charCount[modifiedTarget] || 0;
};


/**
 * javascript判断以英文字母和英文.开头
 * @param {*} str 
 * @returns 
 */
export function isValid(str) 
{
    return /^[A-Za-z][A-Za-z\.]/.test(str);
}

// 示例使用
// const originalString = "Hello, this is a test string.";
// const searchString = "test";
// const resultString = replaceBefore(originalString, searchString);
// console.log(resultString); // 输出: " string."
export function replaceBefore(originalString, searchString) 
{
    const regex = new RegExp(`^.*${searchString}`);
    let str = originalString.replace(regex, '');
    return str.replace(searchString,"");
}

export function isStartWithDafa(str,startStr) {
    return str.startsWith(startStr);
}

export function extractBase64(str) {
    let base64Regex = /^(data:\s*image\/(\w+);base64,)/;
    let match = base64Regex.exec(str);
    return match ? match[1] : null;
}

export function extractBase64Images(htmlString) {
    const base64Regex = /(?<=(src|srcset)=['"])data:image\/[^;]+;base64,([^"']+)/g;
    const matches = htmlString.match(base64Regex);
    return matches || [];
}

export function dateStrReplace(str) 
{
    // 先判断：
    let resOne = str.includes("：");
    if (resOne) 
    {
        str = str.replace("：", ":");
    }
    let charArray = str.split(':');
    if(charArray.length != 2)
    {
        return "";
    }
    if(isNaN(charArray[0]) || isNaN(charArray[1]))
    {
        return "";
    }
    if(charArray[0].length > 2 || charArray[1].length > 2)
    {
        return "";
    }
    let front = charArray[0];
    if(front > 23 || front < 0)
    {
        return "";
    }
    let end = charArray[1];
    if(end > 59 || end < 0)
    {
        return "";
    }
    if(charArray[0].length == 1)
    {
        front = "0" + charArray[0];
    }
    if(charArray[1].length == 1)
    {
        end = "0" + charArray[1];
    }
    let result = front + ":" + end;
    return result;
}


