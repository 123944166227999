
import Vue from 'vue'
import Loading from './loading.vue'
import {sleep} from '../../utils/function'
const loadingBox = Vue.extend(Loading)
loadingBox.install = function (data) {

    let instance = new loadingBox({
    data
    }).$mount()
    document.body.appendChild(instance.$el)
    return {
    show:()=>{
        Vue.nextTick(() => {
        // show 和弹窗组件里的show对应，用于控制显隐
        instance.show = true
        })
    },
    hide:()=>{
        Vue.nextTick(() => {
        // show 和弹窗组件里的show对应，用于控制显隐
        sleep(1000).then(()=>{
            instance.show = false
        })
        })
    }
    }
}
export default loadingBox.install
